<template>
    <div class="dataSet">
        <div class="buttonBox">
            <el-button size="small" type="primary" @click="addclick">新建</el-button>
            <el-button size="small" type="danger" @click="selectDelete">删除</el-button>
        </div>
        <div class="tableBox">
            <el-table
                :data="tableData"
                :tree-props="{ children: 'children' }"
                row-key="dsName"
                border
                style="width: 100%"
                height="100%"
                @selection-change="handleSelectionChange"
                >
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
                <el-table-column prop="showOrder" label="顺序" width="70" align="center"></el-table-column>
                <el-table-column prop="dsName" label="数据集名称" align="center"></el-table-column>
                <el-table-column prop="referCondition" label="外键" align="center"></el-table-column>
                <el-table-column prop="connName" label="数据库连接" align="center"></el-table-column>
                <el-table-column prop="dsType" label="数据集类型" align="center"></el-table-column>
                <!-- <el-table-column label="操作" width="80" align="center" slot-scope="scope">
                    <el-button @click="setclick" type="text" icon="el-icon-edit"></el-button>
                </el-table-column> -->
                 <el-table-column fixed="right" label="操作" width="100"  align="center" >
                    <template slot-scope="scope">
                        <el-button @click="setclick(scope.row)" type="text" icon="el-icon-edit"></el-button>
                         <el-popover
                            placement="bottom"
                            width="200"
                            :ref="'popover-' + scope.row.id"
                            trigger="click"
                        >
                                <div class="delTip"><i class="el-icon-warning" style="color:#E6A23C"></i>确定删除吗？</div>
                                <div style="text-align: right; margin: 0">
                                <el-button size="mini" @click="pCancel(scope.row.id)">取消</el-button>
                                <el-button type="primary" size="mini" @click="deleteDeviceRow(scope.row)">确定</el-button>
                                </div>
                                <el-button style="color: red;" slot="reference" type="text" icon="el-icon-delete"></el-button>
                        </el-popover>
                        <!-- <el-popconfirm
                            confirm-button-text='好的'
                            cancel-button-text='不用了'
                            icon="el-icon-info"
                            icon-color="red"
                            title="这是一段内容确定删除吗？"
                        >
                            <el-button style="color: red;" @click="deletelist(scope.row)" type="text" icon="el-icon-delete"></el-button>
                        </el-popconfirm> -->
                        
                    </template>
                </el-table-column>
            </el-table>
             <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                background
                :current-page="page"
                :page-sizes="[20, 50, 100, 200]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { getdataSetlistPage, deletedataSetlist } from "@/api/dataSet/index.js"
export default {
    name: 'dataSet',
    data () {
        return{
            tableData: [],
            page: 1,
            pageSize: 20,
            total: 0,
            deletelist: [],
            ids:[]
        }
    },
    mounted () {
        this.ioin()
    },
    methods: {
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
        },
        selectDelete() {
            if(this.ids.length >= 1) {
                this.$confirm(' 是否继续删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deletedataSetlist(this.ids).then(res => {
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        this.ioin()
                    })
                })
            }
        },
        deleteDeviceRow (row) {
            this.deletelist = []
            this.pClose(row.id)
            let data = {
                ids: ""
            }
            data.ids = row.id
            this.deletelist.push(data.ids)
            deletedataSetlist(this.deletelist).then(res => {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.ioin()
            })
        },
        pCancel(id) {
            this.pClose(id)
        },
        pClose(id) {
            this.$refs[`popover-` + id].doClose()
        },
        handleSizeChange (val) {
            this.pageSize = val
            this.ioin()
        },
        handleCurrentChange (val) {
            this.page = val
            this.ioin()
        },
        addclick () {
            this.$router.push({
                name:'setDataSet'
            })
        },
        setclick (row) {
            this.$router.push({
                name:'addDataSet',
                params: row
            })
        },
        ioin () {
            let obj = {
                pageNum: 1,
                pageSize: 20,
                queryKey: ''
            }
            obj.pageNum = this.page
            obj.pageSize = this.pageSize
            getdataSetlistPage(obj).then(res => {
                this.page = res.pageNum
                this.pageSize = res.pageSize
                this.total = res.totalRecord
                this.tableData = res.result
            })
        },
    }
}
</script>
<style scoped lang="less">
.dataSet{
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 5px;
    position: relative;
}
.buttonBox {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}
.tableBox {
    height: ceil(90% - 10px);
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
}
.el-pagination {
    position: absolute;
    bottom: 5px;
    left: 5px;
}
</style>